import React from "react"

import "./implementations.less"
import "../../styles/layout.less"
import ImplementationsCarousel from "./implementationsCarousel"

const Implementations = props => (
  <div className="implementations-container">
    <div className="implementations-baner">
      <div className="implementation-baner-bg1"></div>
      <div className="implementation-baner-bg2"></div>
      <h2 className="implementations-subtitle section-subtitle">
        Nasze wdrożenia kluczy systemowych
      </h2>
    </div>
    <ImplementationsCarousel />
  </div>
)

export default Implementations

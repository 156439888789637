import React from "react"

import "./contact.less"
import "../../styles/layout.less"
import ContactInfo from "./contactInfo"

const ContactSection = () => (
  <section className="contact">
    <ContactInfo />
  </section>
)

export default ContactSection

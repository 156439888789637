import React from "react"
import "../../styles/layout.less"
import "./offer.less"
import { Link } from "gatsby"

const OfferBox = () => (
  <section className="offer-container">
    <div className="container">
      <div className="row">
        <div className="col">
          {/* <h2 className="section-subtitle">Oferta</h2> */}
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <Link to="/oferta">
                <div className="offer-image keys">
                  <div className="offer-circle">
                    <div className="offer-icon key"></div>
                  </div>
                </div>
                <h2 className="offer-subtitle">Dorabianie kluczy mieszkaniowych</h2>
              </Link>
            </div>
            <div className="col-sm-6 col-md-4">
              <Link to="/oferta">
                <div className="offer-image system-key">
                  <div className="offer-circle">
                    <div className="offer-icon system-key"></div>
                  </div>
                </div>
                <h2 className="offer-subtitle">Produkcja systemów Master Key</h2>
              </Link>
            </div>
            <div className="col-sm-6 col-md-4">
              <Link to="/uslugi">
                <div className="offer-image shoes">
                  <div className="offer-circle">
                    <div className="offer-icon shoe"></div>
                  </div>
                </div>
                <h2 className="offer-subtitle">Usługi szewskie</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default OfferBox

import React, { Component } from "react"
import Carousel, { consts } from "react-elastic-carousel"

import impl1 from "../../images/implementations/impl1.png"
import impl2 from "../../images/implementations/impl2.png"
import impl3 from "../../images/implementations/impl3.png"
import impl4 from "../../images/implementations/impl4.png"
import impl5 from "../../images/implementations/impl5.png"
import impl6 from "../../images/implementations/impl6.png"
import impl7 from "../../images/implementations/impl7.png"
import impl8 from "../../images/implementations/impl8.png"
import impl9 from "../../images/implementations/impl9.png"
import impl10 from "../../images/implementations/impl10.png"
import impl11 from "../../images/implementations/impl11.png"
import impl12 from "../../images/implementations/impl12.png"

import "./implementations.less"

import styled from "styled-components"

class ImplementationsCarousel extends Component {
  constructor(props) {
    super(props)
    this.breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      { width: 850, itemsToShow: 3 },
      { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    ]
  }

  myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? "<" : ">"

    const ArrowButton = styled.button`
      background-color: transparent;
      border: none;
      font-size: 1.5rem;
      font-weight: 900;
    `
    return (
      <ArrowButton onClick={onClick} disabled={isEdge}>
        {pointer}
      </ArrowButton>
    )
  }

  render() {
    const Item1 = styled.div`
      width: auto;
    `

    return (
      <Carousel breakPoints={this.breakPoints} renderArrow={this.myArrow}>
        <Item1>
          <img src={impl1} alt="aluminex" />
        </Item1>
        <Item1>
          <img src={impl2} alt="heiztechnik" />
        </Item1>
        <Item1>
          <img src={impl3} alt="conrad" />
        </Item1>
        <Item1>
          <img src={impl4} alt="dworek" />
        </Item1>
        <Item1>
          <img src={impl5} alt="nasza szkola" />
        </Item1>
        <Item1>
          <img src={impl6} alt="remontowa" />
        </Item1>
        <Item1>
          <img src={impl7} alt="inspektorat" />
        </Item1>
        <Item1>
          <img src={impl8} alt="ciesla" />
        </Item1>
        <Item1>
          <img src={impl9} alt="GAiT" />
        </Item1>
        <Item1>
          <img src={impl10} alt="avega" />
        </Item1>
        <Item1>
          <img src={impl11} alt="baukrane" />
        </Item1>
        <Item1>
          <img src={impl12} alt="asm" />
        </Item1>
      </Carousel>
    )
  }
}

export default ImplementationsCarousel

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSlider from "../components//carousel/carousel"
import OfferBox from "../components/offer/offer"
import ContactSection from "../components/contact/contact"
import Implementations from "../components/implementations/implementations"

import '../styles/layout.less'




const IndexPage = (props) => (
  <>
    <Layout>
      <HeroSlider />
      <Implementations />
      <OfferBox />
      <ContactSection contactData={props.contactData} />
    </Layout>
    <SEO title="Klucze" />
  </>
)

export default IndexPage

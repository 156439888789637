import React, { useState } from "react"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Button,
} from "reactstrap"

import "./carousel.less"
import slide1Img from "../../images/slide-master.png"
import slide2Img from "../../images/keys.png"
import { Link } from "gatsby"

const items = [
  {
    altText: "Klucze systemowe",
    title: "Produkcja systemów Master Key",
    caption: "Jeden klucz do wielu drzwi",
    class: "slide1",
    img: `${slide1Img}`,
    link: "/oferta",
  },
  {
    altText: "klucze domowe",
    title: "Dorabiamy klucze domowe",
    caption: "Przyjdź i dorób klucz do domu",
    class: "slide2",
    img: `${slide2Img}`,
    link: "/oferta",
  },
]

const HeroSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map(item => {
    return (
      <CarouselItem
        className={item.class}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.class}
      >
        <div className="slide-text">
          <CarouselCaption
            className="slide-text-title"
            captionText={item.caption}
            captionHeader={item.title}
          />
          <Link to={item.link}>
            <Button className="slide-button">Sprawdź</Button>
          </Link>
        </div>
        <div className="slide-img">
          <img src={item.img} alt={item.altText} />
        </div>
      </CarouselItem>
    )
  })

  return (
    <>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </>
  )
}

export default HeroSlider
